import style from "./index.module.scss";
import { Icon } from "@/shared/ui";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "use-intl";
import { CabinetCard } from "@/components/Card";
import { useAuth } from "@/hooks";
import { useAppDispatch } from "@/store";
import { setCurrentUser } from "@/store/auth";

const GENERAL_SETTINGS = [
  {
    text: "language",
    type: "language",
    color: "#40C067",
    url: "/cabinet/settings?page=language",
  },
  {
    text: "2fa",
    type: "2fa",
    color: "#40C067",
    url: "/cabinet/settings?page=2fa",
  },
  {
    text: "observers",
    type: "observer",
    color: "#40C067",
    url: "/cabinet/observers",
  },
];

const SUB_ACCOUNT_SETTINGS = [
  {
    text: "workers",
    color: "#40C067",
    type: "worker",
    url: "/cabinet/workers",
  },
  {
    text: "subaccounts",
    color: "#40C067",
    type: "subaccounts",
    url: "/cabinet/subaccounts",
  },
  {
    text: "api",
    type: "api",
    color: "#40C067",
    url: "/cabinet/settings?page=api",
  },
  {
    text: "logout",
    type: "logout",
    color: "#AEAEB7",
    url: "",
  },
];

export function CabinetMobileSettings() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const logoutUser = () => {
    logout().then(() => {
      dispatch(setCurrentUser(null));
      navigate("/");
    });
  };
  const i18n = useTranslations("cabinet.mobile");
  return (
    <div className={style.settings}>
      <h1>{i18n("title")}</h1>
      <div className={style.settings__list}>
        {GENERAL_SETTINGS.map((set, idx) => (
          <CabinetCard
            className={style.settings__list__item}
            key={idx}
            onClick={() => navigate(set.url)}
          >
            <div className={style.settings__list__item__icon}>
              <Icon type={set.type as any} color={set.color} />
            </div>
            <p>{i18n(set.text)}</p>
          </CabinetCard>
        ))}
      </div>
      <h1>{i18n("subaccountTitle")}</h1>
      <div className={style.settings__list}>
        {SUB_ACCOUNT_SETTINGS.map((set, idx) => (
          <CabinetCard
            className={style.settings__list__item}
            key={idx}
            onClick={() =>
              set.type !== "logout" ? navigate(set.url) : logoutUser()
            }
          >
            <div
              className={classNames(
                style.settings__list__item__icon,
                set.type === "logout" ? style.logout : "",
              )}
            >
              <Icon type={set.type as any} color={set.color} />
            </div>
            <p>{i18n(set.text)}</p>
          </CabinetCard>
        ))}
      </div>
    </div>
  );
}
