import React, { InputHTMLAttributes } from "react";
import classNames from "classnames";
import style from "./input.module.scss";

interface IProps extends InputHTMLAttributes<any> {
  error: any;
  label?: string;
  appendIcon?: React.ReactNode;
}

function Component({ className, appendIcon, ...props }: IProps) {
  return (
    <div className={style.wrapper}>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <div>
        <input
          type="text"
          className={classNames(
            props.error ? "error" : "",
            style.input,
            appendIcon && style.padding,
            className,
          )}
          {...props}
        />
        {appendIcon}
      </div>
      {props.error && <span className={style.error}>{props.error}</span>}
    </div>
  );
}

const CInput = React.memo(Component);
export { CInput };
