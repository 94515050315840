import { createBrowserRouter } from "react-router-dom";
import MainPage from "@/pages/main";
import Login from "@/pages/auth/login";
import SignUp from "@/pages/auth/signup";
import { MainLayout } from "@/layouts";
import { CabinetLayout } from "@/layouts/cabinet-layout";
import Statistic from "@/pages/cabinet/statistic";
import Earnings from "@/pages/cabinet/earnings";
import Payouts from "@/pages/cabinet/payouts";
import Settings from "@/pages/cabinet/settings";
import SubAccountsPage from "@/pages/cabinet/subaccounts";
import SubAccountsWorkers from "@/pages/cabinet/workers";
import PolicyPage from "@/pages/policy";
import AboutUs from "@/pages/about-us";
import TariffPage from "@/pages/tariff";
import Licenses from "@/pages/licenses";
import SupportPage from "@/pages/support";
import Rules from "@/pages/rules";
import { LangLayout } from "@/layouts/lang-layout";
import VerifyPage from "@/pages/auth/verify";
import ResetPage from "@/pages/auth/reset";
import { MainCabinetLayout } from "@/layouts/main-cabinet-layout";
import ObserversPage from "@/pages/cabinet/observers";
import CustomObserverLinkPage from "@/pages/observer";
const router = createBrowserRouter([
  {
    path: "",
    element: (
      <LangLayout>
        <MainPage />
      </LangLayout>
    ),
  },
  {
    path: "login",
    element: (
      <MainLayout>
        <Login />
      </MainLayout>
    ),
  },
  {
    path: "verify",
    element: (
      <MainLayout>
        <VerifyPage />
      </MainLayout>
    ),
  },
  {
    path: "reset",
    element: (
      <MainLayout>
        <ResetPage />
      </MainLayout>
    ),
  },
  {
    path: "signup",
    element: (
      <MainLayout>
        <SignUp />
      </MainLayout>
    ),
  },
  {
    path: "cabinet",
    element: (
      <MainCabinetLayout>
        <CabinetLayout></CabinetLayout>
      </MainCabinetLayout>
    ),
    children: [
      {
        path: "statistics",
        element: <Statistic />,
      },
      {
        path: "earnings",
        element: <Earnings />,
      },
      {
        path: "workers",
        element: <SubAccountsWorkers />,
      },
      {
        path: "payout",
        element: <Payouts />,
      },
      {
        path: "observers",
        element: <ObserversPage />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "subaccounts",
        element: <SubAccountsPage />,
      },
    ],
  },
  {
    path: "observer-link/:token",
    element: (
      <MainLayout>
        <CustomObserverLinkPage />
      </MainLayout>
    ),
  },
  {
    path: "policy",
    element: (
      <MainLayout>
        <PolicyPage />
      </MainLayout>
    ),
  },
  {
    path: "about-us",
    element: (
      <MainLayout>
        <AboutUs />
      </MainLayout>
    ),
  },
  {
    path: "tariff",
    element: (
      <MainLayout>
        <TariffPage />
      </MainLayout>
    ),
  },
  {
    path: "licenses",
    element: (
      <MainLayout>
        <Licenses />
      </MainLayout>
    ),
  },
  {
    path: "rules",
    element: (
      <MainLayout>
        <Rules />
      </MainLayout>
    ),
  },
  {
    path: "support",
    element: (
      <MainLayout>
        <SupportPage />
      </MainLayout>
    ),
  },
]);

export default router;
