import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CREATE_SUB_ACCOUNT,
  GET_ALL_SUB_ACCOUNTS,
  GET_STATISTICS,
  GET_SUB_ACCOUNT_CHART,
  GET_SUB_ACCOUNT_EARNINGS,
  GET_SUB_ACCOUNT_PAYOUT,
  GET_SUB_ACCOUNT_STRATUM,
  GET_SUB_ACCOUNT_SUMMARY,
  GET_SUB_ACCOUNT_WORKERS,
} from "@/store/sub_accounts/variables";
import {
  createSubAccount,
  getStatistics,
  getSubAccountChart,
  getSubAccounts,
  getSubAccountsEarning,
  getSubAccountsPayouts,
  getSubAccountsSummary,
  getSubAccountStratumUrls,
  getSubAccountsWorkers,
} from "@/store/sub_accounts/api";
import {
  StatisticResponseDto,
  SubAccountCreateBodyDto,
  SubAccountSummaryDto,
} from "@/models";

export interface IApiKey {
  loading: boolean;
  stratumUrls: Array<any>;
  subAccounts: Array<any>;
  earnings: Array<any>;
  workers: Array<any>;
  allWorkers: Array<any>;
  subAccountChart: {
    hashrates: number[];
    now: any;
  };
  subAccountSummary: SubAccountSummaryDto[];
  subAccountPayout: any;
  showModal: boolean;
  statistics: StatisticResponseDto[];
  currentSubAccountInfo: any;
  currentSubAccount: string;
}
const initialState: IApiKey = {
  loading: false,
  stratumUrls: [],
  subAccounts: [],
  subAccountChart: { hashrates: [], now: "" },
  subAccountSummary: [],
  allWorkers: [],
  workers: [],
  earnings: [],
  statistics: [],
  showModal: false,
  currentSubAccountInfo: null,
  subAccountPayout: [],
  currentSubAccount: "",
};

const getAllSubAccounts$ = createAsyncThunk(GET_ALL_SUB_ACCOUNTS, async () => {
  const response = await getSubAccounts();
  return Promise.resolve(response.data);
});

const getSubAccountSummary$ = createAsyncThunk(
  GET_SUB_ACCOUNT_SUMMARY,
  async (name: string, { signal }) => {
    const response = await getSubAccountsSummary(name, { signal });
    const result = response.data as { data: SubAccountSummaryDto };
    return Promise.resolve([result.data]);
  },
);

const getSubAccountPayout$ = createAsyncThunk(
  GET_SUB_ACCOUNT_PAYOUT,
  async (name: string, thunkAPI) => {
    const response = await getSubAccountsPayouts(name, {
      signal: thunkAPI.signal,
    });
    return Promise.resolve(response.data);
  },
);

const getSubAccountWorkers$ = createAsyncThunk(
  GET_SUB_ACCOUNT_WORKERS,
  async (
    params: { sub_account_name: string; worker_status: string },
    thunkAPI,
  ) => {
    const response = await getSubAccountsWorkers(params, {
      signal: thunkAPI.signal,
    });
    return Promise.resolve(response.data);
  },
);

const createSubAccount$ = createAsyncThunk(
  CREATE_SUB_ACCOUNT,
  async (body: SubAccountCreateBodyDto) => {
    return createSubAccount(body)
      .then((res: any) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e.response.data?.error_code || 5000);
      });
  },
);

const getSubAccountStratum$ = createAsyncThunk(
  GET_SUB_ACCOUNT_STRATUM,
  async (name: string) => {
    return getSubAccountStratumUrls(name).then((response) => response.data);
  },
);

const getSubAccountEarnings$ = createAsyncThunk(
  GET_SUB_ACCOUNT_EARNINGS,
  async (name: string, { signal }) => {
    const response = await getSubAccountsEarning(name, { signal });
    return Promise.resolve(response.data);
  },
);

const getStatistics$ = createAsyncThunk(GET_STATISTICS, async () => {
  const response = await getStatistics();
  return Promise.resolve(
    ["BTC", "BCH", "LTC"].map(
      (key) => (response.data as any)[key] as StatisticResponseDto,
    ),
  );
});

const getSubAccountChart$ = createAsyncThunk(
  GET_SUB_ACCOUNT_CHART,
  async (body: { sub_account_name: string; interval: string }, { signal }) => {
    const response = await getSubAccountChart(body, signal);
    return Promise.resolve(response.data);
  },
);

export const subAccountSlice = createSlice({
  name: "subaccount",
  initialState,
  reducers: {
    setCurrentSubAccount(state, { payload }) {
      state.currentSubAccount = payload;
    },
    setCurrentSubAccountInfo(state, { payload }) {
      state.currentSubAccountInfo = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setShowModal(state, { payload }) {
      state.showModal = payload;
    },
    setWorkersForFilter(state, { payload }) {
      state.workers = [...payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubAccountEarnings$.fulfilled, (state, action) => {
      state.earnings = action.payload as any;
      state.loading = false;
    });
    builder.addCase(
      getAllSubAccounts$.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.subAccounts = payload as any;
        if (payload.length) {
          state.currentSubAccount = payload[0].name;
          state.currentSubAccountInfo = payload[0];
        }
      },
    );
    builder.addCase(getSubAccountSummary$.fulfilled, (state, action) => {
      state.subAccountSummary = action.payload as any;
      state.loading = false;
    });
    builder.addCase(getSubAccountSummary$.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSubAccountPayout$.fulfilled, (state, action) => {
      state.subAccountPayout = action.payload as any;
      state.loading = false;
    });
    builder.addCase(getSubAccountWorkers$.fulfilled, (state, action) => {
      state.workers = action.payload as any;
      state.allWorkers = action.payload as any;
      state.loading = false;
    });
    builder.addCase(createSubAccount$.fulfilled, (state, action) => {
      state.subAccounts.push(action.payload);
    });
    builder.addCase(getSubAccountStratum$.fulfilled, (state, action) => {
      state.stratumUrls = action.payload as any;
    });
    builder.addCase(getStatistics$.fulfilled, (state, action) => {
      state.statistics = action.payload as any;
      state.loading = false;
    });
    builder.addCase(getSubAccountChart$.fulfilled, (state, action) => {
      state.subAccountChart = action.payload as any;
    });
  },
});

export const subAccountReducer = subAccountSlice.reducer;

export const {
  setCurrentSubAccount,
  setLoading,
  setCurrentSubAccountInfo,
  setShowModal,
  setWorkersForFilter,
} = subAccountSlice.actions;

export {
  getAllSubAccounts$,
  getSubAccountPayout$,
  getSubAccountEarnings$,
  getStatistics$,
  getSubAccountWorkers$,
  createSubAccount$,
  getSubAccountStratum$,
  getSubAccountSummary$,
  getSubAccountChart$,
};
