import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { getSubAccountChart$ } from "@/store/sub_accounts";
import { useSubAccount } from "@/hooks";
import { useAppDispatch } from "@/store";
import Chart from "react-apexcharts";
import { formatHash } from "@/utils/to-hash";
import style from "./index.module.scss";
import { useTranslations } from "use-intl";
const TIMES = ["10MIN", "HOUR", "DAY"];

export function StatisticDetails() {
  const { currentSubAccountInfo } = useSubAccount();
  const [chartState, setChartState] = useState({
    series: [
      {
        name: "Hashrate: ",
        data: [] as any,
      },
    ],
    options: {
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
      },
      annotations: {},
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        min: new Date().setDate(new Date().getDate() - 100),
        max: Date.now(),
        borderColor: "#3d7bd9",
        tickAmount: 3,
        labels: {
          style: {
            colors: "#afafaf",
          },
          datetimeUTC: false,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
      },
      yaxis: {
        borderColor: "#40c067",
        type: "category",
        labels: {
          formatter: function (val: any) {
            return formatHash(
              val,
              currentSubAccountInfo?.crypto_currency === "LTC",
            );
          },
        },
      },
      stroke: {
        colors: ["#3d7bd9"],
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy HH:mm",
        },
      },
      fill: {
        colors: "#3a83f1",
        type: "gradient",
        gradient: {
          shade: "dark",
          opacityFrom: 1,
          opacityTo: 0.75,
        },
      },
    },
    selection: "one_year",
  });
  const dispatch = useAppDispatch();
  const { currentSubAccount } = useSubAccount();
  const [loading, setLoading] = useState(true);
  const i18n = useTranslations("cabinet.statistics");
  const [currentTime, setCurrentTime] = useState("10MIN");
  const promise = useRef<any>();
  useEffect(() => {
    if (!currentSubAccount) {
      return;
    }
    promise.current = getGraphic(currentTime);
    return () => {
      if (promise.current) {
        promise.current.abort();
      }
    };
  }, [currentTime, currentSubAccount]);

  const getGraphic = (interval: string) => {
    if (promise.current) {
      promise.current.abort();
    }
    setLoading(true);
    const dispatchReq = dispatch(
      getSubAccountChart$({
        sub_account_name: currentSubAccount,
        interval: interval,
      }),
    );
    dispatchReq
      .unwrap()
      .then((res: any) => {
        setLoading(false);
        const { hashrates, now } = res;
        const data = [];
        const newArray = [0];
        newArray.push(...hashrates);
        switch (interval) {
          case "10MIN":
            for (let x = newArray.length - 1; x >= 0; x--) {
              const newDate = new Date(now);
              newDate.setMinutes(
                newDate.getMinutes() - (newArray.length - x) * 10,
              );
              data.push([newDate, newArray[x]]);
            }
            setChartState({
              ...chartState,
              series: [{ data: data, name: "Hashrate: " }],
              options: {
                ...chartState.options,
                xaxis: {
                  ...chartState.options.xaxis,
                  min: new Date(now).setHours(new Date(now).getHours() - 25),
                },
              },
            });
            break;
          case "HOUR":
            for (let x = newArray.length - 1; x >= 0; x--) {
              const newDate = new Date(now);
              newDate.setHours(newDate.getHours() - (newArray.length - x));
              data.push([newDate, newArray[x]]);
            }
            setChartState({
              ...chartState,
              series: [{ data: data, name: "Hashrate: " }],
              options: {
                ...chartState.options,
                xaxis: {
                  ...chartState.options.xaxis,
                  min: new Date(now).setDate(new Date(now).getDate() - 3.05),
                },
              },
            });
            break;
          case "DAY":
            for (let x = newArray.length - 1; x >= 0; x--) {
              const newDate = new Date(now);
              newDate.setDate(newDate.getDate() - (newArray.length - x));
              data.push([newDate, newArray[x]]);
            }
            setChartState({
              ...chartState,
              series: [{ data: data, name: "Hashrate: " }],
              options: {
                ...chartState.options,
                xaxis: {
                  ...chartState.options.xaxis,
                  min: new Date(now).setDate(new Date(now).getDate() - 90),
                },
              },
            });
            break;
        }
      })
      .catch(() => {
        setLoading(false);
      });
    return dispatchReq;
  };

  return (
    <div>
      <div className={"d-flex align-items-center gap-3 mb-3"}>
        <h3 className={style.graphic__title}>{i18n("graphic")}</h3>
        <DropdownButton
          as={ButtonGroup}
          key={"btn"}
          id={`dropdown-variants-Secondary`}
          variant={"secondary"}
          title={currentTime}
        >
          {TIMES.map((item, idx) => (
            <Dropdown.Item
              eventKey={idx}
              key={idx}
              active={currentTime === item}
              onClick={() => {
                setCurrentTime(item);
              }}
            >
              {item}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>

      <div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div id="chart">
              <div id="chart-timeline">
                <Chart
                  options={chartState.options as any}
                  series={chartState.series}
                  type="area"
                  height={350}
                />
              </div>
            </div>
            <div id="html-dist"></div>
          </>
        )}
      </div>
    </div>
  );
}
