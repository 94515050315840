import { CabinetCard } from "@/components/Card";
import classNames from "classnames";
import { getHashrateValue } from "@/shared";
import style from "./index.module.scss";
import { useSubAccount } from "@/hooks";
import { useTranslations } from "use-intl";

interface IProps {
  earnings: Array<any>;
  summary: any;
}

export function HomeCards({ summary, earnings }: IProps) {
  const { currentSubAccount, currentSubAccountInfo } = useSubAccount();
  const i18n = useTranslations("cabinet.home");
  return (
    <div className={style.home__blocks}>
      <div className={style.home__desktop}>
        <div className={style.home__blocks__first}>
          <CabinetCard>
            <div className={classNames(style.card, style.withIcon)}>
              <div>
                <span className={style.card__label}>
                  {i18n("summaryBlock.lastEarning")}
                </span>
                <h3 className={style.card__title}>
                  {earnings.length ? earnings[0].total_profit : 0}
                  <span className={style.card__subtitle}>
                    {currentSubAccountInfo?.crypto_currency || "BTC"}
                  </span>
                </h3>
                <h6 className={style.card__equal}></h6>
              </div>
              <div className={style.card__img}>
                <img
                  src={require("@/images/main/coins/coin-bitcoin.svg").default}
                  width={70}
                  alt={"btc"}
                />
              </div>
            </div>
          </CabinetCard>
          <CabinetCard>
            <div className={style.card}>
              <span className={style.card__label}>
                {i18n("summaryBlock.currentHashrate")}
              </span>
              <h3 className={style.card__title}>
                {
                  getHashrateValue(
                    summary[0].hashrate_10min as number,
                    currentSubAccountInfo?.crypto_currency === "LTC",
                  ).val
                }
                <span className={style.card__subtitle}>
                  {
                    getHashrateValue(
                      summary[0].hashrate_10min || 0,
                      currentSubAccountInfo?.crypto_currency === "LTC",
                    ).unit
                  }
                </span>
              </h3>
            </div>
          </CabinetCard>
          <CabinetCard>
            <div className={style.card}>
              <span className={style.card__label}>
                {i18n("summaryBlock.onlineWorker")}
              </span>
              <h3 className={classNames(style.card__title, style.green)}>
                {summary[0].active_workers}
              </h3>
            </div>
          </CabinetCard>
        </div>
        <div className={style.home__blocks__first}>
          <CabinetCard>
            <div className={classNames(style.card, style.withIcon)}>
              <div>
                <span className={style.card__label}>
                  {i18n("summaryBlock.balance")}
                </span>
                <h3 className={style.card__title}>
                  {summary[0].balance}
                  <span className={style.card__subtitle}>
                    {currentSubAccountInfo?.crypto_currency || "BTC"}
                  </span>
                </h3>
                <h6 className={style.card__equal}></h6>
              </div>
              <div className={style.card__img}>
                <img
                  src={require("@/images/main/coins/coin-bitcoin.svg").default}
                  width={70}
                  alt={"btc"}
                />
              </div>
            </div>
          </CabinetCard>
          <CabinetCard>
            <div className={style.card}>
              <span className={style.card__label}>
                {i18n("summaryBlock.average")}
              </span>
              <h3 className={style.card__title}>
                {
                  getHashrateValue(
                    summary[0].hashrate_24hour as number,
                    currentSubAccountInfo?.crypto_currency === "LTC",
                  ).val
                }
                <span className={style.card__subtitle}>
                  {
                    getHashrateValue(
                      summary[0].hashrate_24hour || 0,
                      currentSubAccountInfo?.crypto_currency === "LTC",
                    ).unit
                  }
                </span>
              </h3>
            </div>
          </CabinetCard>
          <CabinetCard>
            <div className={style.card}>
              <span className={style.card__label}>
                {i18n("summaryBlock.offlineWorker")}
              </span>
              <h3 className={classNames(style.card__title, style.red)}>
                {summary[0].unactive_workers}
              </h3>
            </div>
          </CabinetCard>
        </div>
      </div>
      {MobileInfoBlocks(i18n, earnings, summary[0], currentSubAccountInfo)}
    </div>
  );
}
const MobileInfoBlocks = (
  i18n: any,
  earnings: any,
  summary: any,
  subAccount: any,
) => {
  return (
    <div className={style.home__mobile}>
      <div className={style.home__blocks__first}>
        <div className={style.home__mobile__extend}>
          <CabinetCard>
            <div className={classNames(style.card, style.withIcon)}>
              <div>
                <span className={style.card__label}>
                  {i18n("summaryBlock.lastEarning")}
                </span>
                <h3 className={style.card__title}>
                  {earnings.length ? earnings[0].total_profit.toFixed(8) : 0}
                  <span className={style.card__subtitle}>
                    {subAccount?.crypto_currency || "BTC"}
                  </span>
                </h3>
                <h6 className={style.card__equal}></h6>
              </div>
              <div className={style.card__img}>
                <img
                  src={require("@/images/main/coins/coin-bitcoin.svg").default}
                  width={40}
                  alt={"btc"}
                />
              </div>
            </div>
          </CabinetCard>
          <CabinetCard>
            <div className={classNames(style.card, style.withIcon)}>
              <div>
                <span className={style.card__label}>
                  {i18n("summaryBlock.balance")}
                </span>
                <h3 className={style.card__title}>
                  {summary.balance}
                  <span className={style.card__subtitle}>
                    {subAccount?.crypto_currency || "BTC"}
                  </span>
                </h3>
                <h6 className={style.card__equal}></h6>
              </div>
              <div className={style.card__img}>
                <img
                  src={require("@/images/main/coins/coin-bitcoin.svg").default}
                  width={40}
                  alt={"btc"}
                />
              </div>
            </div>
          </CabinetCard>
        </div>
      </div>
      <div
        className={classNames(
          style.home__blocks__first,
          style.home__mobile__equalize,
        )}
      >
        <CabinetCard>
          <div className={style.card}>
            <span className={style.card__label}>
              {i18n("summaryBlock.currentHashrate")}
            </span>
            <h3 className={style.card__title}>
              {
                getHashrateValue(
                  summary.hashrate_10min || 0,
                  subAccount?.crypto_currency === "LTC",
                ).val
              }
              <span className={style.card__subtitle}>
                {
                  getHashrateValue(
                    summary.hashrate_10min || 0,
                    subAccount?.crypto_currency === "LTC",
                  ).unit
                }
              </span>
            </h3>
          </div>
        </CabinetCard>
        <CabinetCard>
          <div className={style.card}>
            <span className={style.card__label}>
              {i18n("summaryBlock.average")}
            </span>
            <h3 className={style.card__title}>
              {
                getHashrateValue(
                  summary.hashrate_24hour as number,
                  subAccount?.crypto_currency === "LTC",
                ).val
              }
              <span className={style.card__subtitle}>
                {
                  getHashrateValue(
                    summary.hashrate_24hour || 0,
                    subAccount?.crypto_currency === "LTC",
                  ).unit
                }
              </span>
            </h3>
          </div>
        </CabinetCard>
      </div>
      <div
        className={classNames(
          style.home__blocks__first,
          style.home__mobile__equalize,
        )}
      >
        <CabinetCard>
          <div className={style.card}>
            <span className={style.card__label}>
              {i18n("summaryBlock.onlineWorker")}
            </span>
            <h3 className={classNames(style.card__title, style.green)}>
              {summary.active_workers}
            </h3>
          </div>
        </CabinetCard>
        <CabinetCard>
          <div className={style.card}>
            <span className={style.card__label}>
              {i18n("summaryBlock.offlineWorker")}
            </span>
            <h3 className={classNames(style.card__title, style.red)}>
              {summary.unactive_workers}
            </h3>
          </div>
        </CabinetCard>
      </div>
    </div>
  );
};
