import { useContext, useEffect, useState } from "react";
import { useSubAccount } from "@/hooks/use-subaccount";
import { useDebounce } from "@/shared";

export function useSubaccountSearch() {
  const { subAccounts, currentSubAccount } = useSubAccount();
  const [search, setSearch] = useState("");
  const changeSearchVal = useDebounce(search, 250);

  const [cachedSubAccounts, setCachedSubAccounts] = useState<any[]>([]);
  useEffect(() => {
    setCachedSubAccounts((prev) =>
      subAccounts.filter((subAccount) =>
        subAccount.name.toLowerCase().includes(changeSearchVal.toLowerCase()),
      ),
    );
  }, [changeSearchVal]);
  useEffect(() => {
    setCachedSubAccounts(subAccounts);
  }, [subAccounts]);

  return {
    cachedSubAccounts,
    search,
    setCachedSubAccounts,
    subAccounts,
    setSearch,
    currentSubAccount,
  };
}
