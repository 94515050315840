import classNames from "classnames";
import style from "./index.module.scss";
interface ICard extends React.HTMLAttributes<any> {
  children: any;
  className?: string;
}
export function Card(props: ICard) {
  return (
    <div className={classNames("card", props.className)}>{props.children}</div>
  );
}

export function CabinetCard({ children, className, ...props }: ICard) {
  return (
    <div className={classNames(style.card, className || "")} {...props}>
      {children}
    </div>
  );
}
