import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CREATE_OBSERVER,
  GET_ALL_OBSERVERS,
  WATCHER_LINK,
  WATCHER_LINK_SUBACCOUNTS,
} from "@/store/observers/variables";
import {
  IObserverCreate,
  IObserverDetailParams,
  IWatcherLinkObserverDetail,
  IWatcherLinkSubAccount,
} from "@/models";
import {
  addObserver,
  getAllObservers,
  getDetailWatcherLink,
  getWatcherLinkSubAccounts,
} from "@/store/observers/api";

export interface IObserverState {
  loading: boolean;
  observers: any;
  watchLinkSubAccount: IWatcherLinkSubAccount | null;
  watcherLink: IWatcherLinkObserverDetail | null;
  watcherLinkSubAccounts: IWatcherLinkSubAccount[];
  watcherLoading: boolean;
}
const initialState: IObserverState = {
  loading: false,
  observers: [],
  watcherLoading: false,
  watcherLink: null,
  watchLinkSubAccount: null,
  watcherLinkSubAccounts: [],
};

const getAllObservers$ = createAsyncThunk(GET_ALL_OBSERVERS, async () => {
  const response = await getAllObservers();
  return Promise.resolve(response.data);
});

const getWatcherLinkDetail$ = createAsyncThunk(
  WATCHER_LINK,
  async ({
    token,
    params,
  }: {
    token: string;
    params?: IObserverDetailParams;
  }) => {
    const response = await getDetailWatcherLink(token, params);
    return Promise.resolve(response);
  },
);

const getWatcherLinkSubAccounts$ = createAsyncThunk(
  WATCHER_LINK_SUBACCOUNTS,
  async (token: string) => {
    const response = await getWatcherLinkSubAccounts(token);
    return Promise.resolve(response.data);
  },
);

const createObserver$ = createAsyncThunk(
  CREATE_OBSERVER,
  async (body: IObserverCreate) => {
    const response = await addObserver(body);
    return Promise.resolve(response);
  },
);

export const observerSlice = createSlice({
  name: "reference",
  initialState,
  reducers: {
    changeWatcherSubAccount(state, { payload }) {
      state.watchLinkSubAccount = payload;
    },
    setWatcherWorkers(state, { payload }) {
      if (!state.watcherLink) {
        return;
      }
      state.watcherLink.workers = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllObservers$.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllObservers$.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllObservers$.fulfilled, (state, action) => {
      state.observers = action.payload as any;
      state.loading = false;
    });
    builder.addCase(getWatcherLinkDetail$.pending, (state, action) => {
      state.watcherLoading = true;
    });
    builder.addCase(getWatcherLinkDetail$.rejected, (state, action) => {
      state.watcherLoading = false;
    });
    builder.addCase(getWatcherLinkDetail$.fulfilled, (state, action) => {
      state.watcherLink = action.payload as any;
      state.watcherLoading = false;
    });
    builder.addCase(getWatcherLinkSubAccounts$.fulfilled, (state, action) => {
      state.watcherLinkSubAccounts = action.payload as any;
      state.watchLinkSubAccount = state.watcherLinkSubAccounts[0];
    });
    builder.addCase(createObserver$.fulfilled, (state, action) => {
      state.observers.push(action.payload);
    });
  },
});

export const observerReducer = observerSlice.reducer;
export const { changeWatcherSubAccount, setWatcherWorkers } =
  observerSlice.actions;

export {
  createObserver$,
  getWatcherLinkDetail$,
  getWatcherLinkSubAccounts$,
  getAllObservers$,
};
