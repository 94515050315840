import { RootState, useAppDispatch } from "@/store";
import { useSelector } from "react-redux";
import { useSubAccount } from "@/hooks";
import { useEffect, useState } from "react";
import {
  getSubAccountSummary$,
  getSubAccountWorkers$,
  setLoading,
  setWorkersForFilter,
} from "@/store/sub_accounts";
import { Spinner } from "react-bootstrap";
import { CabinetCard } from "@/components/Card";
import { CustomTable } from "@/components/Table/CustomTable";
import style from "./index.module.scss";
import { useTranslations } from "use-intl";
import { getHashrateValue, Icon, toHashrate } from "@/shared";
import classNames from "classnames";
import { CInput } from "@/components/Input/CInput";
import { CButton } from "@/components";
import { EWorkerStatus } from "@/variables";
import { toDate } from "@/utils";

export default function WorkersPage() {
  const dispatch = useAppDispatch();
  const { workers, allWorkers } = useSelector(
    (state: RootState) => state.subAccount,
  );
  const { currentSubAccount, loading, currentSubAccountInfo } = useSubAccount();
  const summary = useSelector(
    (state: RootState) => state.subAccount.subAccountSummary,
  );
  const [sorts, setSorts] = useState<Partial<{ [key in keyof any]: boolean }>>(
    {},
  );
  const [searchVal, setSearchVal] = useState("");
  const [currentStatus, setCurrentStatus] = useState<string>(EWorkerStatus.ALL);
  useEffect(() => {
    let promise: any;
    if (currentSubAccount) {
      dispatch(setLoading(true));
      promise = dispatch(
        getSubAccountWorkers$({
          sub_account_name: currentSubAccount,
          worker_status: currentStatus,
        }),
      );
    }
    return () => {
      dispatch(setLoading(false));
      if (promise) {
        promise.abort();
      }
    };
  }, [currentSubAccount, currentStatus]);
  useEffect(() => {
    let summaryPromise: any;
    if (currentSubAccount) {
      summaryPromise = dispatch(getSubAccountSummary$(currentSubAccount));
    }
    return () => {
      if (summaryPromise) {
        summaryPromise.abort();
      }
    };
  }, [currentSubAccount]);
  useEffect(() => {
    if (!currentSubAccount) {
      return;
    }
    dispatch(
      setWorkersForFilter(
        allWorkers.filter((worker) =>
          worker.worker_name.toLowerCase().includes(searchVal.toLowerCase()),
        ),
      ),
    );
  }, [searchVal]);
  const sortFields = (field: any, isDesc: boolean) => {
    const returnValue = isDesc ? 1 : -1;
    dispatch(
      setWorkersForFilter(
        [...workers].sort((a, b) => {
          return a[field] > b[field] ? returnValue * -1 : returnValue;
        }),
      ),
    );
  };
  const i18n = useTranslations("cabinet.workers");
  return (
    <div className={style.workers}>
      {summary?.length ? (
        <div className={style.workers__cards}>
          <div className={style.workers__cards__first}>
            <CabinetCard>
              <div className={classNames(style.card, style.withIcon)}>
                <div>
                  <span className={style.card__label}>
                    {i18n("currentHashrate")}
                  </span>
                  <h3 className={style.card__title}>
                    {
                      getHashrateValue(
                        summary[0].hashrate_10min || 0,
                        currentSubAccountInfo?.crypto_currency === "LTC",
                      ).val
                    }
                    <span className={style.card__subtitle}>
                      {
                        getHashrateValue(
                          summary[0].hashrate_10min || 0,
                          currentSubAccountInfo?.crypto_currency === "LTC",
                        ).unit
                      }
                    </span>
                  </h3>
                  <h6 className={style.card__equal}></h6>
                </div>
              </div>
            </CabinetCard>
            <CabinetCard>
              <div className={style.card}>
                <span className={style.card__label}>{i18n("average1h")}</span>
                <h3 className={style.card__title}>
                  {
                    getHashrateValue(
                      summary[0].hashrate_1hour || 0,
                      currentSubAccountInfo?.crypto_currency === "LTC",
                    ).val
                  }
                  <span className={style.card__subtitle}>
                    {
                      getHashrateValue(
                        summary[0].hashrate_1hour || 0,
                        currentSubAccountInfo?.crypto_currency === "LTC",
                      ).unit
                    }
                  </span>
                </h3>
              </div>
            </CabinetCard>
            <CabinetCard>
              <div className={style.card}>
                <span className={style.card__label}>{i18n("average24h")}</span>
                <h3 className={classNames(style.card__title)}>
                  {
                    getHashrateValue(
                      summary[0].hashrate_24hour || 0,
                      currentSubAccountInfo?.crypto_currency === "LTC",
                    ).val
                  }
                  <span className={style.card__subtitle}>
                    {
                      getHashrateValue(
                        summary[0].hashrate_24hour || 0,
                        currentSubAccountInfo?.crypto_currency === "LTC",
                      ).unit
                    }
                  </span>
                </h3>
              </div>
            </CabinetCard>
          </div>
          <div className={style.workers__cards__first}>
            <CabinetCard>
              <div className={classNames(style.card, style.withIcon)}>
                <div>
                  <span className={style.card__label}>
                    {i18n("onlineWorkers")}
                  </span>
                  <h3 className={classNames(style.card__title, style.green)}>
                    {summary[0].active_workers}
                  </h3>
                </div>
              </div>
            </CabinetCard>
            <CabinetCard>
              <div className={style.card}>
                <span className={style.card__label}>
                  {i18n("offlineWorkers")}
                </span>
                <h3 className={classNames(style.card__title, style.red)}>
                  {summary[0].unactive_workers}
                </h3>
              </div>
            </CabinetCard>
            <CabinetCard>
              <div className={style.card}>
                <span className={style.card__label}>
                  {i18n("totalWorkers")}
                </span>
                <h3 className={classNames(style.card__title)}>
                  {summary[0].active_workers + summary[0].unactive_workers}
                </h3>
              </div>
            </CabinetCard>
          </div>
        </div>
      ) : (
        ""
      )}
      {loading ? (
        <Spinner animation={"grow"} />
      ) : (
        <div className={style.workers__content}>
          <div className={style.workers__content__heading}>
            <div className={style.workers__search}>
              <CInput
                type={"search"}
                error={""}
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
                placeholder={i18n("search")}
              />
            </div>
            <div>
              <CButton
                value={i18n("total")}
                variant={
                  currentStatus !== EWorkerStatus.ALL ? "text" : undefined
                }
                onClick={() => setCurrentStatus(EWorkerStatus.ALL)}
              />
              <CButton
                value={i18n("online")}
                variant={
                  currentStatus !== EWorkerStatus.ONLINE ? "text" : undefined
                }
                onClick={() => setCurrentStatus(EWorkerStatus.ONLINE)}
              />
              <CButton
                value={i18n("offline")}
                variant={
                  currentStatus !== EWorkerStatus.OFFLINE ? "text" : undefined
                }
                onClick={() => setCurrentStatus(EWorkerStatus.OFFLINE)}
              />
            </div>
          </div>

          <CustomTable
            notFoundText={""}
            headers={[
              <div>
                {i18n("table.worker")}
                <span
                  className={!!sorts["worker_name"] ? style.rotate : ""}
                  onClick={() => {
                    const currentState = !!sorts["worker_name"];
                    setSorts((prev) => ({
                      ...prev,
                      worker_name: !currentState,
                    }));
                    sortFields("worker_name", !currentState);
                  }}
                >
                  <Icon type={"sortable"} />
                </span>
              </div>,
              i18n("table.status"),
              <div>
                {i18n("table.hashrate10m")}

                <span
                  className={!!sorts["hashrate_10m"] ? style.rotate : ""}
                  onClick={() => {
                    const currentState = !!sorts["hashrate_10m"];
                    setSorts((prev) => ({
                      ...prev,
                      hashrate_10m: !currentState,
                    }));
                    sortFields("hashrate_10m", !currentState);
                  }}
                >
                  <Icon type={"sortable"} />
                </span>
              </div>,
              <div>
                {i18n("table.hashrate1h")}
                <span
                  className={!!sorts["hashrate_1h"] ? style.rotate : ""}
                  onClick={() => {
                    const currentState = !!sorts["hashrate_1h"];
                    setSorts((prev) => ({
                      ...prev,
                      hashrate_1h: !currentState,
                    }));
                    sortFields("hashrate_1h", !currentState);
                  }}
                >
                  <Icon type={"sortable"} />
                </span>
              </div>,
              <div>
                {i18n("table.hashrate24h")}
                <span
                  className={!!sorts["hashrate_24h"] ? style.rotate : ""}
                  onClick={() => {
                    const currentState = !!sorts["hashrate_24h"];
                    setSorts((prev) => ({
                      ...prev,
                      hashrate_24h: !currentState,
                    }));
                    sortFields("hashrate_24h", !currentState);
                  }}
                >
                  <Icon type={"sortable"} />
                </span>
              </div>,
              <div>
                {i18n("table.lastShareTime")}
                <span
                  className={!!sorts["last_share_datetime"] ? style.rotate : ""}
                  onClick={() => {
                    const currentState = !!sorts["last_share_datetime"];
                    setSorts((prev) => ({
                      ...prev,
                      last_share_datetime: !currentState,
                    }));
                    sortFields("last_share_datetime", !currentState);
                  }}
                >
                  <Icon type={"sortable"} />
                </span>
              </div>,
            ]}
            columns={workers.map((worker) => {
              return {
                expandable: false,
                data: [
                  worker["worker_name"],
                  worker["status"],
                  toHashrate(
                    worker["hashrate_10m"],
                    currentSubAccountInfo?.crypto_currency === "LTC",
                  ),
                  toHashrate(
                    worker["hashrate_1h"],
                    currentSubAccountInfo?.crypto_currency === "LTC",
                  ),
                  toHashrate(
                    worker["hashrate_24h"],
                    currentSubAccountInfo?.crypto_currency === "LTC",
                  ),
                  toDate(worker["last_share_datetime"]),
                ],
              };
            })}
          />
        </div>
      )}
    </div>
  );
}
