import { Container, Form } from "react-bootstrap";
import style from "./index.module.scss";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useScrollingUp } from "@/utils";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/store";
import React, { useEffect, useState } from "react";
import {
  getAllSubAccounts$,
  setCurrentSubAccount,
  setShowModal,
} from "@/store/sub_accounts";
import { useAuth, useSubAccount } from "@/hooks";
import { setCurrentUser } from "@/store/auth";
import { useTranslations } from "use-intl";
import { ELANG, LanguageComponent } from "@/shared/ui/language-component";

interface IProps {
  className?: string;
}

export function Header({ className }: IProps) {
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const dispatch = useAppDispatch();
  const i18n = useTranslations("header");
  const { logout } = useAuth();
  const getButtons = () => {
    if (currentUser) {
      return (
        <>
          <Link
            to={"/cabinet/statistics"}
            className={"btn btn-sm btn-light text-black"}
          >
            {i18n("personalCabinet")}
          </Link>

          <a
            href={"#"}
            className={style.header__right__logout}
            onClick={(e) => {
              e.preventDefault();
              logout().then(() => {
                dispatch(setCurrentUser(null));
                navigate("/");
              });
            }}
          >
            <i className="fa fa-sign-out"></i>
          </a>
        </>
      );
    } else {
      return (
        <div>
          <Link to={"/login"} className={"btn-sm btn"}>
            {i18n("signIn")}
          </Link>
          <Link to={"/signup"} className={"btn btn-sm btn-light text-black"}>
            {i18n("signUp")}
          </Link>
        </div>
      );
    }
  };
  return (
    <div className={classNames(style.header__wrapper, className)}>
      <div className={style.header__inner}>
        <Container className={"container-lg"}>
          <div className={classNames(style.header, "sticky-top")}>
            <div className={style.header__left}>
              <div onClick={() => navigate("/")}>
                <img
                  src={require("@/images/main/main-logo.png")}
                  alt={"main-icon"}
                />
              </div>
            </div>
            <div className={style.header__right}>{getButtons()}</div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export function CabinetHeader({ className }: IProps) {
  const [showLangs, setShowLangs] = useState(false);
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const dispatch = useAppDispatch();
  const i18n = useTranslations("header");
  const lang = useSelector((state: RootState) => state.auth.lang);
  const getButtons = () => {
    if (currentUser) {
      return (
        <>
          <div className={classNames(style.header__right__lang, "relative")}>
            <button onClick={() => setShowLangs((prev) => !prev)}>
              <span>{ELANG[lang]}</span>
              <img
                src={require("@/images/main/bottom-arrow.svg").default}
                alt="lang"
              />
            </button>
            {showLangs && <LanguageComponent setShowLangs={setShowLangs} />}
          </div>
        </>
      );
    } else {
      return (
        <div>
          <Link to={"/login"} className={"btn-sm btn"}>
            {i18n("signIn")}
          </Link>
          <Link to={"/signup"} className={"btn btn-sm btn-light text-black"}>
            {i18n("signUp")}
          </Link>
        </div>
      );
    }
  };
  useEffect(() => {
    dispatch(getAllSubAccounts$());
  }, []);
  return (
    <div className={classNames(style.header__wrapper, className, "bg-white")}>
      <div className={style.header__inner}>
        <div>
          <div
            className={classNames(
              style.header,
              "sticky-top",
              style.header__cabinet,
            )}
          >
            <div className={style.header__mobile}>
              <div onClick={() => navigate("/")}>
                <img
                  src={require("@/images/main/main-logo-sticky.png")}
                  alt={"main-icon"}
                />
              </div>
            </div>
            <div className={style.header__right}>{getButtons()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
