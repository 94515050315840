import style from "@/layouts/cabinet-layout/index.module.scss";
import sidebarStyle from "./sidebar.module.scss";
import classNames from "classnames";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslations } from "use-intl";
import {
  setCurrentSubAccount,
  setCurrentSubAccountInfo,
  setShowModal,
} from "@/store/sub_accounts";
import { useAuth, useSubaccountSearch } from "@/hooks";
import { useAppDispatch } from "@/store";
import { CDropdown } from "@/components/Dropdown";
import { CInput } from "@/components/Input/CInput";
import { CURRENT_SUBACCOUNT_KEY } from "@/variables";
import { Icon } from "@/shared";
import { setCurrentUser } from "@/store/auth";

export const BTNS = [
  {
    name: "statistics",
    label: "btn.statistics",
    url: "/cabinet/statistics",
    type: "dashboard",
  },
  {
    name: "workers",
    label: "btn.workers",
    url: "/cabinet/workers",
    type: "worker",
  },
  {
    name: "earnings",
    label: "btn.earnings",
    url: "/cabinet/earnings",
    type: "earnings",
  },
  {
    name: "payout",
    label: "btn.payouts",
    url: "/cabinet/payout",
    type: "payout",
  },
  {
    name: "subaccounts",
    label: "btn.subaccount",
    url: "/cabinet/subaccounts",
    type: "subaccounts",
  },
  {
    name: "observers",
    label: "btn.observers",
    url: "/cabinet/observers",
    type: "observer",
  },
];
const BOTTOM_ITEMS = [
  {
    title: "settings",
    url: "/cabinet/settings",
    type: "settings",
  },
  {
    title: "logout",
    url: "/cabinet/logout",
    type: "logout",
  },
];
export function SidebarComponent() {
  const navigate = useNavigate();
  const i18n = useTranslations("cabinet");
  const sidebarI18n = useTranslations("cabinet.sidebar");
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { logout } = useAuth();

  const {
    cachedSubAccounts,
    search,
    setSearch,
    subAccounts,
    currentSubAccount,
  } = useSubaccountSearch();
  return (
    <div className={classNames("w-[100%]", sidebarStyle.sidebar)}>
      <div className={"text-center pt-[20px] h-[75px] cursor-pointer"}>
        <div onClick={() => navigate("/")}>
          <img
            src={require("@/images/main/main-logo-sticky.png")}
            alt={"main-icon"}
            className={"m-auto"}
          />
        </div>
      </div>

      <div className={"p-[10px] pb-0 pt-[20px]"}>
        <CDropdown
          options={cachedSubAccounts.map((account: any) => ({
            label: account.name,
            key: account.name,
          }))}
          label={"Subaccounts"}
          customList={
            <div onClick={() => dispatch(setShowModal(true))}>
              {sidebarI18n("create")}
            </div>
          }
          searchBlock={
            <CInput
              error={""}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={sidebarI18n("search")}
            />
          }
          value={currentSubAccount}
          onChange={(val) => {
            localStorage.setItem(
              CURRENT_SUBACCOUNT_KEY,
              JSON.stringify(
                subAccounts.find((acc: any) => acc.name === val) || {},
              ),
            );
            dispatch(setCurrentSubAccount(val));
            dispatch(
              setCurrentSubAccountInfo(
                subAccounts.find((acc: any) => acc.name === val),
              ),
            );
          }}
        />
      </div>
      <div className={sidebarStyle.sidebar__content__wrapper}>
        <div className={sidebarStyle.sidebar__content__navbar}>
          {BTNS.map((item, idx) => (
            <Link
              to={item.url}
              key={idx}
              className={item.url === pathname ? sidebarStyle.active : ""}
            >
              <Icon type={item.type as any} color={false ? "#fff" : ""} />
              <span>{i18n(item.label)}</span>
            </Link>
          ))}
        </div>
        <div
          className={classNames(
            sidebarStyle.sidebar__content__bottomBar,
            sidebarStyle.sidebar__content__navbar,
          )}
        >
          {BOTTOM_ITEMS.map((item, idx) => (
            <Link
              to={item.url}
              key={idx}
              onClick={(e) => {
                if (item.type === "logout") {
                  e.preventDefault();
                  dispatch(setCurrentUser(null));
                  logout().then(() => navigate("/"));
                }
              }}
              className={item.url === pathname ? sidebarStyle.active : ""}
            >
              <Icon type={item.type as any} />
              <span>{sidebarI18n(item.title)}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
