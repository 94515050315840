import style from "./index.module.scss";
import { Icon, TIcons } from "@/shared/ui";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useTranslations } from "use-intl";

const ITEMS = [
  {
    title: "main",
    url: "/cabinet/statistics",
    type: "dashboard",
  },
  {
    title: "history",
    type: "earnings",
    url: "/cabinet/earnings",
  },
  {
    title: "payout",
    type: "payout",
    url: "/cabinet/payout",
  },
  {
    title: "settings",
    type: "settings",
    url: "/cabinet/settings",
  },
];

export function MobileBottomNavigation() {
  const { pathname } = useLocation();
  const i18n = useTranslations("cabinet.sidebar");
  return (
    <div className={style.navigation}>
      {ITEMS.map((item, idx) => (
        <Link
          to={item.url}
          className={classNames(
            style.navigation__item,
            item.url === pathname ? style.active : "",
          )}
          key={idx}
        >
          <Icon type={item.type as TIcons} color={"#AEAEB7"} />
          <span>{i18n(item.title)}</span>
        </Link>
      ))}
    </div>
  );
}
