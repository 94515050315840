import { Icon } from "@/shared/ui";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/store";
import { useEffect } from "react";
import { getSubAccountStratum$ } from "@/store/sub_accounts";
import style from "./index.module.scss";
import { toast } from "react-toastify";
import { useTranslations } from "use-intl";
import { CabinetCard, Card } from "@/components/Card";
import { useSubAccount } from "@/hooks";

export function CabinetStatrumUrlsComponent() {
  const statrumUrls = useSelector(
    (state: RootState) => state.subAccount.stratumUrls,
  );
  const dispatch = useAppDispatch();
  const { currentSubAccount } = useSubAccount();

  useEffect(() => {
    if (currentSubAccount) {
      dispatch(getSubAccountStratum$(currentSubAccount)).unwrap();
    }
  }, [currentSubAccount]);
  const i18n = useTranslations("cabinet");
  return (
    <div>
      <div className={style.mining}>
        <CabinetCard>
          <h3 className={style.mining__heading}>{i18n("mining.title")}</h3>
          <p className={style.mining__subtitle}>{i18n("mining.subtitle")}</p>
          <div className={style.mining__urls}>
            {statrumUrls.map((obj, key) => (
              <div key={key}>
                <div>{obj.description}</div>
                <div>
                  <span>{obj.url}</span>
                  <span
                    onClick={() => {
                      toast.success(i18n("copied"));
                      navigator.clipboard.writeText(obj.url).then(
                        function () {},
                        function (err) {},
                      );
                    }}
                  >
                    <Icon type={"copy"} />
                  </span>
                </div>
              </div>
            ))}
            <div>
              <div>{i18n("mining.worker")}</div>
              <div>
                <span>{currentSubAccount}</span>
                <span
                  onClick={() => {
                    toast.success(i18n("copied"));
                    navigator.clipboard.writeText(currentSubAccount).then(
                      function () {},
                      function () {},
                    );
                  }}
                >
                  <Icon type={"copy"} />
                </span>
              </div>
            </div>
          </div>
        </CabinetCard>
      </div>
    </div>
  );
}
