import { customRequest } from "@/utils/custom-fetch";
import axiosClient from "@/libs/axiosClient";
import { computeParams } from "@/utils/to-params";
import { downloadXls } from "@/utils/download-xls";

enum API_URL {
  all = "/api/v1/pools/sub_account/all/",
  create = "/api/v1/pools/sub_account/create/",
  summary = "/api/v1/pools/sub_account/summary/",
  earnings = "/api/v1/pools/sub_account/earnings/",
  payouts = "/api/v1/pools/sub_account/payouts/",
  workers = "/api/v1/pools/sub_account/workers/",
  stratumUrl = "/api/v1/pools/sub_account/stratum_url/",
  statistics = "/api/v1/statistic/crypto_currency/",
  chart = "/api/v1/pools/sub_account/observer/hashrate_chart/",
  earningExportXls = "/api/v1/pools/sub_account/earnings/xls/",
  payoutExportXls = "/api/v1/pools/sub_account/payouts/xls/",
}

export const getSubAccounts = () => {
  return customRequest.getItem(API_URL.all);
};

export const getSubAccountsSummary = (
  name: string,
  { signal }: { signal: any },
) => {
  return customRequest.getItem(
    API_URL.summary + `?sub_account_name=${name}`,
    "",
    { signal },
  );
};

export const getSubAccountsPayouts = (
  name: string,
  { signal }: { signal: any },
) => {
  return customRequest.getItem(
    API_URL.payouts + `?sub_account_name=${name}`,
    "",
    { signal: signal },
  );
};

export const getSubAccountsWorkers = (
  params: { sub_account_name: string; worker_status: string },
  { signal }: { signal: any },
) => {
  return customRequest.getItem(API_URL.workers, computeParams(params), {
    signal: signal,
  });
};

export const getSubAccountsEarning = (
  name: string,
  { signal }: { signal: any },
) => {
  return customRequest.getItem(
    API_URL.earnings + `?sub_account_name=${name}`,
    "",
    { signal },
  );
};

export const createSubAccount = (body: any) => {
  return customRequest.post(API_URL.create, body);
};

export const getSubAccountStratumUrls = (name: string) => {
  return customRequest.getItem(
    API_URL.stratumUrl + `?sub_account_name=${name}`,
  );
};

export const getStatistics = () => {
  return axiosClient.get(API_URL.statistics);
};

export const getSubAccountChart = (
  {
    sub_account_name,
    interval,
  }: {
    sub_account_name: string;
    interval: string;
  },
  signal?: any,
) => {
  return customRequest.getItem(
    API_URL.chart +
      `?interval=${interval}&sub_account_name=${sub_account_name}`,
    "",
    { signal },
  );
};
export const exportEarningXls = (params: {
  sub_account_name: string;
  start_date: string | null;
  end_date: string | null;
}) => {
  return customRequest
    .getItem(API_URL.earningExportXls + computeParams(params), "", {
      responseType: "blob",
    })
    .then((response: any) => {
      downloadXls(response);
    });
};

export const exportPayoutXls = (params: {
  sub_account_name: string;
  start_date: string | null;
  end_date: string | null;
}) => {
  return customRequest
    .getItem(API_URL.payoutExportXls + computeParams(params), "", {
      responseType: "blob",
    })
    .then((response: any) => {
      downloadXls(response);
    });
};
