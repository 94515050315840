import style from "./index.module.scss";
import classNames from "classnames";
import { RootState, useAppDispatch } from "@/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getStatistics$ } from "@/store/sub_accounts";
import { Spinner } from "react-bootstrap";
import { useTranslations } from "use-intl";
import { expo, formatHash, toDifficulty } from "@/utils/to-hash";
export function Table() {
  const dispatch = useAppDispatch();
  const i18n = useTranslations("main.secondBlock");
  const statistics = useSelector(
    (state: RootState) => state.subAccount.statistics,
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(getStatistics$()).finally(() => setLoading(false));
  }, []);
  return (
    <table className={classNames("table", style.table)}>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">{i18n("currency")}</th>
          <th scope="col">{i18n("price")}</th>
          <th scope="col">{i18n("hashrate")}</th>
          <th scope="col">{i18n("difficulty")}</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr className={"text-center"}>
            <td colSpan={5}>
              <Spinner animation={"grow"} />
            </td>
          </tr>
        ) : (
          statistics.map((stat, idx) => (
            <tr key={idx}>
              <th>{idx + 1}</th>
              <td className={classNames("d-flex", style.table__customTd)}>
                <div>
                  <img
                    src={require(
                      `@/images/main/coins/${stat.crypto_currency}.png`,
                    )}
                    alt="coin-img"
                    width={33}
                  />
                </div>
                <p>
                  {stat.name} <span>{stat.crypto_currency}</span>
                </p>
              </td>
              <td>
                <span className="numeral">${stat.price_usd.toFixed(3)}</span>
              </td>
              <td>
                <span className="numeral">
                  {formatHash(stat.network_hashrate)}
                </span>
              </td>
              <td>
                <span className="numeral">{toDifficulty(stat.difficulty)}</span>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}
