import { CabinetCard, Card } from "@/components/Card";
import { Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "@/store";
import {
  getWatcherLinkDetail$,
  getWatcherLinkSubAccounts$,
  setWatcherWorkers,
} from "@/store/observers";
import { useSelector } from "react-redux";
import { useChart } from "@/hooks/use-chart";
import Chart from "react-apexcharts";
import { CustomTable } from "@/components/Table/CustomTable";
import { extractDate, toDate } from "@/utils";
import { formatHash } from "@/utils/to-hash";
import { useTranslations } from "use-intl";
import style from "./index.module.scss";
import { IObserverDetailParams } from "@/models";
import { HomeCards } from "@/features/ui/home-cards";
import { Icon, toHashrate } from "@/shared";
import { setWorkersForFilter } from "@/store/sub_accounts";

export default function CustomObserverLinkPage() {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const { watcherLoading, watcherLink, watchLinkSubAccount } = useSelector(
    (state: RootState) => state.observer,
  );
  const [params, setParams] = useState<IObserverDetailParams>({
    start_date: extractDate(
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
    ),
    end_date: extractDate(new Date()),
    sub_account_name: "",
  });
  const [sorts, setSorts] = useState<Partial<{ [key in keyof any]: boolean }>>(
    {},
  );
  const sortFields = (field: any, isDesc: boolean) => {
    if (!watcherLink?.workers) {
      return;
    }
    const returnValue = isDesc ? 1 : -1;
    dispatch(
      setWatcherWorkers(
        [...watcherLink.workers].sort((a, b) => {
          return a[field] > b[field] ? returnValue * -1 : returnValue;
        }),
      ),
    );
  };
  const i18n = useTranslations("cabinet");
  const { chart, setChartByInterval } = useChart(watchLinkSubAccount);
  useEffect(() => {
    if (!token) {
      return;
    }
    dispatch(getWatcherLinkSubAccounts$(token)).unwrap().then();
  }, [token]);
  useEffect(() => {
    if (!watchLinkSubAccount) {
      return;
    }
    setParams({ ...params, sub_account_name: watchLinkSubAccount.name });
  }, [watchLinkSubAccount]);
  useEffect(() => {
    if (!token || !params.sub_account_name) {
      return;
    }
    dispatch(getWatcherLinkDetail$({ token, params }))
      .unwrap()
      .then((res: any) => {
        if (res && res.hashrate_chart) {
          setChartByInterval(res.hashrate_chart.interval, res.hashrate_chart);
        }
      });
  }, [params]);
  return (
    <div className={style.observer}>
      <div className={"container pt-5"}>
        {watcherLoading ? (
          <div className={"text-center"}>
            <Spinner />
          </div>
        ) : (
          watcherLink && (
            <div>
              <h2 className={"text-black"}>{i18n("observers.title")}</h2>
              <div className={"mt-4"}>
                <Row>
                  <HomeCards
                    earnings={watcherLink.earnings || []}
                    summary={[
                      {
                        hashrate_10min:
                          watcherLink.statistics?.hashrate_10m || 0,
                        hashrate_1hour:
                          watcherLink.statistics?.hashrate_1h || 0,
                        balance: watcherLink.balance || 0,
                        hashrate_24hour:
                          watcherLink.statistics?.hashrate_24h || 0,
                        active_workers:
                          watcherLink.statistics?.online_workers || 0,
                        unactive_workers:
                          watcherLink.statistics?.offline_workers || 0,
                      },
                    ]}
                  />
                </Row>
              </div>
              {watcherLink.hashrate_chart && (
                <>
                  <h4 className={"text-black mt-[20px]"}>
                    {i18n("observers.form.graphic")}
                  </h4>
                  <div className={"mt-4"}>
                    <div id="chart">
                      <div id="chart-timeline">
                        <Chart
                          options={chart.options as any}
                          series={chart.series}
                          type="area"
                          height={350}
                        />
                      </div>
                    </div>
                    <div id="html-dist"></div>
                  </div>
                </>
              )}
              {watcherLink.earnings && (
                <>
                  <h4 className={"text-black mt-4"}>
                    {i18n("observers.form.earningEnabled")}
                  </h4>
                  <div className={"mt-4"}>
                    <CustomTable
                      notFoundText={i18n("notFoundText")}
                      headers={[
                        i18n("earnings.header.date"),
                        i18n("earnings.header.profit"),
                        i18n("earnings.header.hashrate"),
                      ]}
                      columns={watcherLink.earnings.map((earning: any) => {
                        return {
                          expandable: false,
                          data: ["date", "total_profit", "hashrate"].map(
                            (key) => {
                              if (key === "date") {
                                return toDate(earning[key]);
                              } else if (key === "hashrate") {
                                return formatHash(
                                  earning[key],
                                  watchLinkSubAccount?.crypto_currency ===
                                    "LTC",
                                );
                              }
                              return earning[key].toFixed(8);
                            },
                          ),
                        };
                      })}
                    />
                  </div>
                </>
              )}
              {watcherLink.workers && (
                <>
                  <h4 className={"text-black mt-4"}>
                    {i18n("observers.form.workersEnabled")}
                  </h4>
                  <div className={"mt-4"}>
                    <CustomTable
                      notFoundText={i18n("notFoundText")}
                      headers={[
                        <div>
                          {i18n("workers.table.worker")}
                          <span
                            className={
                              !!sorts["worker_name"] ? style.rotate : ""
                            }
                            onClick={() => {
                              const currentState = !!sorts["worker_name"];
                              setSorts((prev) => ({
                                ...prev,
                                worker_name: !currentState,
                              }));
                              sortFields("worker_name", !currentState);
                            }}
                          >
                            <Icon type={"sortable"} />
                          </span>
                        </div>,
                        i18n("workers.table.status"),
                        <div>
                          {i18n("workers.table.hashrate10m")}

                          <span
                            className={
                              !!sorts["hashrate_10m"] ? style.rotate : ""
                            }
                            onClick={() => {
                              const currentState = !!sorts["hashrate_10m"];
                              setSorts((prev) => ({
                                ...prev,
                                hashrate_10m: !currentState,
                              }));
                              sortFields("hashrate_10m", !currentState);
                            }}
                          >
                            <Icon type={"sortable"} />
                          </span>
                        </div>,
                        <div>
                          {i18n("workers.table.hashrate1h")}
                          <span
                            className={
                              !!sorts["hashrate_1h"] ? style.rotate : ""
                            }
                            onClick={() => {
                              const currentState = !!sorts["hashrate_1h"];
                              setSorts((prev) => ({
                                ...prev,
                                hashrate_1h: !currentState,
                              }));
                              sortFields("hashrate_1h", !currentState);
                            }}
                          >
                            <Icon type={"sortable"} />
                          </span>
                        </div>,
                        <div>
                          {i18n("workers.table.hashrate24h")}
                          <span
                            className={
                              !!sorts["hashrate_24h"] ? style.rotate : ""
                            }
                            onClick={() => {
                              const currentState = !!sorts["hashrate_24h"];
                              setSorts((prev) => ({
                                ...prev,
                                hashrate_24h: !currentState,
                              }));
                              sortFields("hashrate_24h", !currentState);
                            }}
                          >
                            <Icon type={"sortable"} />
                          </span>
                        </div>,
                        <div>
                          {i18n("workers.table.lastShareTime")}
                          <span
                            className={
                              !!sorts["last_share_datetime"] ? style.rotate : ""
                            }
                            onClick={() => {
                              const currentState =
                                !!sorts["last_share_datetime"];
                              setSorts((prev) => ({
                                ...prev,
                                last_share_datetime: !currentState,
                              }));
                              sortFields("last_share_datetime", !currentState);
                            }}
                          >
                            <Icon type={"sortable"} />
                          </span>
                        </div>,
                      ]}
                      columns={watcherLink.workers.map((worker: any) => {
                        return {
                          expandable: false,
                          data: [
                            worker["worker_name"],
                            worker["status"],
                            toHashrate(
                              worker["hashrate_10m"],
                              watchLinkSubAccount?.crypto_currency === "LTC",
                            ),
                            toHashrate(
                              worker["hashrate_1h"],
                              watchLinkSubAccount?.crypto_currency === "LTC",
                            ),
                            toHashrate(
                              worker["hashrate_24h"],
                              watchLinkSubAccount?.crypto_currency === "LTC",
                            ),
                            toDate(worker["last_share_datetime"]),
                          ],
                        };
                      })}
                    />
                  </div>
                </>
              )}
              {watcherLink.payouts && (
                <>
                  <h4 className={"text-black mt-4"}>
                    {i18n("observers.form.payoutEnabled")}
                  </h4>
                  <div className={"mt-4"}>
                    <CustomTable
                      notFoundText={i18n("notFoundText")}
                      headers={[
                        i18n("payout.header.date"),
                        i18n("payout.header.amount"),
                        i18n("payout.header.address"),
                        "TxID",
                      ]}
                      columns={watcherLink.payouts.map((payout: any) => {
                        return {
                          data: ["paid_date", "amount", "address", "tx_id"].map(
                            (key) => {
                              if (key === "tx_id") {
                                return (
                                  <a
                                    href={`https://blockchair.com/ru/bitcoin/transaction/${payout.tx_id}`}
                                    className={"aHref"}
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {payout.tx_id}
                                  </a>
                                );
                              }
                              if (key === "paid_date") {
                                return toDate(payout[key]);
                              } else if (key === "amount") {
                                return payout[key].toFixed(8);
                              }
                              return payout[key];
                            },
                          ),
                        };
                      })}
                    />
                  </div>
                </>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
}
