export const USER_TOKEN = "USER_TOKEN";
export const USER_INFO = "USER_INFO";
export const LANG_STORAGE_KEY = "LANG_KEY";
export const CURRENT_SUBACCOUNT_KEY = "CURRENT_SUBACCOUNT_KEY";
export const EWorkerStatus: { [key in string]: string } = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  DEAD: "DEAD",
  ALL: "",
};
