import { useState } from "react";
import { formatHash } from "@/utils/to-hash";

type TInterval = "10MIN" | "HOUR" | "DAY";

export function useChart(subAccount?: any) {
  const [chart, setChart] = useState({
    series: [
      {
        name: "Hashrate: ",
        data: [] as any,
      },
    ],
    options: {
      chart: {
        id: "area-datetime",
        background: "#0d2138",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
      },
      annotations: {},
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 3,
        colors: ["#3d7bd9"],
        strokeColor: "#00BAEC",
        strokeWidth: 2,
      },
      xaxis: {
        type: "datetime",
        min: new Date().setDate(new Date().getDate() - 100),
        max: Date.now(),
        tickAmount: 3,
        labels: {
          datetimeUTC: false,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
      },
      yaxis: {
        type: "category",
        labels: {
          formatter: function (val: any) {
            return formatHash(val, subAccount?.crypto_currency === "LTC");
          },
        },
      },
      stroke: {
        colors: ["#3d7bd9"],
      },
      theme: {
        mode: "dark",
        palette: "palette7",
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy HH:mm",
        },
      },
      fill: {
        colors: "#3a83f1",
        type: "solid",
        gradient: {
          shade: "dark",
          opacityFrom: 1,
          opacityTo: 0.75,
        },
      },
    },
    selection: "one_year",
  });
  const setChartByInterval = (interval: TInterval, res: any) => {
    const { hashrates, now } = res;
    const data = [];
    const newArray = [0];
    newArray.push(...hashrates);
    switch (interval) {
      case "10MIN":
        for (let x = newArray.length - 1; x >= 0; x--) {
          const newDate = new Date(now);
          newDate.setMinutes(newDate.getMinutes() - (newArray.length - x) * 10);
          data.push([newDate, newArray[x]]);
        }
        setChart({
          ...chart,
          series: [{ data: data, name: "Hashrate: " }],
          options: {
            ...chart.options,
            xaxis: {
              ...chart.options.xaxis,
              min: new Date(now).setHours(new Date(now).getHours() - 25),
            },
          },
        });
        break;
      case "HOUR":
        for (let x = newArray.length - 1; x >= 0; x--) {
          const newDate = new Date(now);
          newDate.setHours(newDate.getHours() - (newArray.length - x));
          data.push([newDate, newArray[x]]);
        }
        setChart({
          ...chart,
          series: [{ data: data, name: "Hashrate: " }],
          options: {
            ...chart.options,
            xaxis: {
              ...chart.options.xaxis,
              min: new Date(now).setDate(new Date(now).getDate() - 3.05),
            },
          },
        });
        break;
      case "DAY":
        for (let x = newArray.length - 1; x >= 0; x--) {
          const newDate = new Date(now);
          newDate.setDate(newDate.getDate() - (newArray.length - x));
          data.push([newDate, newArray[x]]);
        }
        setChart({
          ...chart,
          series: [{ data: data, name: "Hashrate: " }],
          options: {
            ...chart.options,
            xaxis: {
              ...chart.options.xaxis,
              min: new Date(now).setDate(new Date(now).getDate() - 90),
            },
          },
        });
        break;
    }
  };
  return {
    chart,
    setChartByInterval,
  };
}
