export function toHashrate(hashrate: number, isLTC?: boolean): string {
  let prefix = isLTC ? " GH/s" : " TH/s";
  if (isLTC) {
    return (hashrate / 1000).toFixed(3) + prefix;
  }
  if (hashrate < 1_000_000_000) {
    return (hashrate / 1_000_000).toFixed(3) + " TH/s";
  } else if (hashrate < 1_000_000_000_000) {
    return (hashrate / 1_000_000_000).toFixed(3) + " PH/s";
  } else {
    return (hashrate / 1_000_000_000_000).toFixed(3) + " EH/s";
  }
}
export function getHashrateValue(
  hashrate: number,
  isLTC?: boolean,
): {
  val: string;
  unit: string;
} {
  if (isLTC) {
    return {
      val: (hashrate / 1000).toFixed(3),
      unit: " GH/s",
    };
  }
  if (hashrate < 1_000_000_000) {
    return {
      val: (hashrate / 1_000_000).toFixed(3),
      unit: " TH/s",
    };
  } else if (hashrate < 1_000_000_000_000) {
    return {
      val: (hashrate / 1_000_000_000).toFixed(3),
      unit: " PH/s",
    };
  } else {
    return {
      val: (hashrate / 1_000_000_000_000).toFixed(3),
      unit: " EH/s",
    };
  }
}
export function expo(x: number, f: number) {
  return Number.parseFloat(x as any).toExponential(f);
}
