import { RootState, useAppDispatch } from "@/store";
import { useSelector } from "react-redux";
import { useSubAccount } from "@/hooks";
import { useEffect } from "react";
import { getAllSubAccounts$, setLoading } from "@/store/sub_accounts";
import { Spinner } from "react-bootstrap";
import { CabinetCard, Card } from "@/components/Card";
import { CustomTable } from "@/components/Table/CustomTable";
import { useTranslations } from "use-intl";

export default function SubAccountsPage() {
  const dispatch = useAppDispatch();
  const subAccounts = useSelector(
    (state: RootState) => state.subAccount.subAccounts,
  );
  const { currentSubAccount, loading } = useSubAccount();
  useEffect(() => {
    let promise: any;
    dispatch(setLoading(true));
    promise = dispatch(getAllSubAccounts$());
    promise.finally(() => dispatch(setLoading(false)));
    return () => {
      dispatch(setLoading(false));
      if (promise) {
        promise.abort();
      }
    };
  }, []);
  const i18n = useTranslations("cabinet");
  return (
    <>
      {loading ? (
        <Spinner animation={"grow"} />
      ) : (
        <CabinetCard className={"mb-[20px]"}>
          <CustomTable
            notFoundText={i18n("notFoundText")}
            headers={[
              "ID",
              i18n("subaccounts.header.username"),
              i18n("subaccounts.header.commission"),
              i18n("subaccounts.header.currency"),
              i18n("subaccounts.header.wallet"),
              i18n("subaccounts.header.earningScheme"),
            ]}
            columns={subAccounts.map((account) => {
              return {
                data: Object.values(account),
              };
            })}
          />
        </CabinetCard>
      )}
    </>
  );
}
