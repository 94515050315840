import { SetStateAction, useContext, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { setLang } from "@/store/auth";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/store";
import { LANG_STORAGE_KEY } from "@/variables";

export const ELANG: { [key in string]: string } = {
  en: "EN",
  kz: "KZ",
  ru: "RU",
};

export const LANGS = [
  {
    name: "English",
    code: "en",
    icon: "fi fi-us",
  },
  {
    name: "Русский",
    code: "ru",
    icon: "fi fi-ru",
  },
  {
    name: "Қазақша",
    code: "kz",
    icon: "fi fi-kz",
  },
];

export function LanguageComponent({
  setShowLangs,
}: {
  setShowLangs: React.Dispatch<SetStateAction<boolean>>;
}) {
  const { pathname } = useLocation();
  const [langs, setLangs] = useState(LANGS);
  const dispatch = useAppDispatch();
  const lang = useSelector((state: RootState) => state.auth.lang);
  useLayoutEffect(() => {
    setLangs([...langs]);
  }, [pathname]);
  return (
    <div className="langs-drop" id="langBox">
      <ul className="langs-list">
        {langs.map((value, key) => (
          <li
            onClick={(e) => {
              e.preventDefault();
              dispatch(setLang(value.code));
              localStorage.setItem(LANG_STORAGE_KEY, value.code);
              setShowLangs(false);
            }}
            key={key}
            className={lang === value.code ? "active" : ""}
          >
            <a href="#" className="js_changeLang">
              <span className={value.icon}></span>
              <span>{value.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
