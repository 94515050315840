import { CabinetHeader } from "@/components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { setCurrentUser, setIsLogged } from "@/store/auth";
import { IntlProvider } from "use-intl";
import { messages } from "@/libs";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { SidebarComponent } from "@/components/Sidebar";
import style from "./index.module.scss";
import classNames from "classnames";
import { MobileBottomNavigation } from "@/features";

export function MainCabinetLayout({ children }: { children: any }) {
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const lang = useSelector((state: RootState) => state.auth.lang);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser) {
      const givenDate = new Date((currentUser as any).created_date);
      givenDate.setDate(givenDate.getDate() + 60);
      if (new Date() < givenDate) {
        dispatch(setIsLogged(true));
      } else {
        dispatch(setIsLogged(false));
        dispatch(setCurrentUser(null));
      }
    }
  }, [currentUser]);
  return (
    <>
      <IntlProvider messages={messages[lang]} locale={lang}>
        <div className={"flex w-full h-[100vh]"}>
          <div
            className={classNames("w-[300px] shrink-0 h-full", style.sidebar)}
          >
            <SidebarComponent />
          </div>
          <MobileBottomNavigation />
          <div
            className={classNames("w-[100%] overflow-hidden", style.wrapper)}
          >
            <CabinetHeader />
            <ErrorBoundary>
              <div className={style.main}>{children}</div>
            </ErrorBoundary>
          </div>
        </div>
      </IntlProvider>
    </>
  );
}
