import { Header } from "@/components";
import { Footer } from "@/components/Footer";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { IntlProvider } from "use-intl";
import { messages } from "@/libs";
import { ErrorBoundary } from "@/components/ErrorBoundary";

export function MainLayout({ children }: { children: any }) {
  const lang = useSelector((state: RootState) => state.auth.lang);
  return (
    <>
      <IntlProvider messages={messages[lang]} locale={lang}>
        <Header />
        <ErrorBoundary>{children}</ErrorBoundary>
        <Footer />
      </IntlProvider>
    </>
  );
}
