import { RootState, useAppDispatch } from "@/store";
import { useSelector } from "react-redux";
import { useSubAccount } from "@/hooks";
import { useEffect, useState } from "react";
import {
  exportEarningXls,
  exportPayoutXls,
  getSubAccountPayout$,
  setLoading,
} from "@/store/sub_accounts";
import { Button, Spinner } from "react-bootstrap";
import { CabinetCard, Card } from "@/components/Card";
import { CustomTable } from "@/components/Table/CustomTable";
import { toDate } from "@/utils";
import { useTranslations } from "use-intl";

export default function Payouts() {
  const dispatch = useAppDispatch();
  const payouts = useSelector(
    (state: RootState) => state.subAccount.subAccountPayout,
  );
  const { currentSubAccount, loading } = useSubAccount();
  const [loadingXls, setLoadingXls] = useState(false);
  useEffect(() => {
    let promise: any;
    if (currentSubAccount) {
      dispatch(setLoading(true));
      promise = dispatch(getSubAccountPayout$(currentSubAccount));
    }
    return () => {
      dispatch(setLoading(false));
      if (promise) {
        promise.abort();
      }
    };
  }, [currentSubAccount]);
  const i18n = useTranslations("cabinet");
  return (
    <>
      {loading ? (
        <Spinner animation={"grow"} />
      ) : (
        <>
          <div className={"d-flex justify-content-end w-100 mb-4"}>
            {loadingXls ? (
              <Spinner />
            ) : (
              <Button
                onClick={() => {
                  setLoadingXls(true);
                  exportPayoutXls({
                    sub_account_name: currentSubAccount,
                    start_date: null,
                    end_date: null,
                  }).then(() => setLoadingXls(false));
                }}
              >
                {i18n("payout.exportXls")}
              </Button>
            )}
          </div>
          <CabinetCard className={"mb-[20px]"}>
            <CustomTable
              notFoundText={i18n("notFoundText")}
              headers={[
                i18n("payout.header.date"),
                i18n("payout.header.amount"),
                i18n("payout.header.address"),
                "TxID",
              ]}
              columns={payouts.map((payout: any) => {
                return {
                  expandable: false,
                  data: ["paid_date", "amount", "address", "tx_id"].map(
                    (key) => {
                      if (key === "tx_id") {
                        return (
                          <a
                            href={`https://blockchair.com/ru/bitcoin/transaction/${payout.tx_id}`}
                            className={"aHref"}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            {payout.tx_id}
                          </a>
                        );
                      }
                      if (key === "paid_date") {
                        return toDate(payout[key]);
                      } else if (key === "amount") {
                        return payout[key].toFixed(8);
                      }
                      return payout[key];
                    },
                  ),
                };
              })}
            />
          </CabinetCard>
        </>
      )}
    </>
  );
}
