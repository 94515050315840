import { CabinetCard, Card } from "@/components/Card";
import { RootState, useAppDispatch } from "@/store";
import { useEffect, useState } from "react";
import { useSubAccount } from "@/hooks";
import {
  exportEarningXls,
  getSubAccountEarnings$,
  setLoading,
} from "@/store/sub_accounts";
import { useSelector } from "react-redux";
import { CustomTable } from "@/components/Table/CustomTable";
import { Button, Spinner } from "react-bootstrap";
import { toDate } from "@/utils";
import { formatHash } from "@/utils/to-hash";
import { useTranslations } from "use-intl";

export default function Earnings() {
  const dispatch = useAppDispatch();
  const i18n = useTranslations("cabinet");
  const earnings = useSelector((state: RootState) => state.subAccount.earnings);
  const [loadingXls, setLoadingXls] = useState(false);
  const { currentSubAccount, loading, currentSubAccountInfo } = useSubAccount();
  useEffect(() => {
    let promise: any;
    if (currentSubAccount) {
      dispatch(setLoading(true));
      promise = dispatch(getSubAccountEarnings$(currentSubAccount));
    }
    return () => {
      dispatch(setLoading(false));
      if (promise) {
        promise.abort();
      }
    };
  }, [currentSubAccount]);
  return (
    <>
      {loading ? (
        <Spinner animation={"grow"} />
      ) : (
        <>
          <div className={"d-flex justify-content-end w-100 mb-4"}>
            {loadingXls ? (
              <Spinner />
            ) : (
              <Button
                onClick={() => {
                  setLoadingXls(true);
                  exportEarningXls({
                    sub_account_name: currentSubAccount,
                    start_date: null,
                    end_date: null,
                  }).then(() => setLoadingXls(false));
                }}
              >
                {i18n("earnings.exportXls")}
              </Button>
            )}
          </div>
          <CabinetCard className={"mb-[20px]"}>
            <CustomTable
              notFoundText={i18n("notFoundText")}
              headers={[
                i18n("earnings.header.date"),
                i18n("earnings.header.profit"),
                i18n("earnings.header.hashrate"),
              ]}
              columns={earnings.map((earning) => {
                return {
                  data: ["date", "total_profit", "hashrate"].map((key) => {
                    if (key === "date") {
                      return toDate(earning[key]);
                    } else if (key === "hashrate") {
                      return formatHash(
                        earning[key],
                        currentSubAccountInfo?.crypto_currency === "LTC",
                      );
                    }
                    return earning[key].toFixed(8);
                  }),
                };
              })}
            />
          </CabinetCard>
        </>
      )}
    </>
  );
}
